<template>
  <v-dialog v-model="dialog" max-width="1200px"
            :persistent="loadingUpdateName || loading || loadingImprint || loadingKeywords || loadingCreatePixel || loadingCreateCampaign">

    <v-dialog v-model="isVisibleErrorDialog" max-width="900px">
      <v-card>
        <v-card-title class="red">
          <span class="headline">Error</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <p class="text-h6">{{ errorMessage }}</p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmPixel" max-width="900px">
      <v-card>
        <v-card-title class="red">
          <span class="headline">Delete Pixel settings for {{ pixelType }}?</span>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click.stop="confirmPixel = false">Cancel</v-btn>
          <v-btn color="error" @click.stop="deletePixel" :loading="loadingDeletePixel">
            Delete
            <v-icon>mdi-delete</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
        <!--      <v-card-text>-->
        <!--        <v-container>-->
        <!--          <v-row>-->
        <!--            <v-col cols="12" sm="12" md="12">-->
        <!--              <p class="text-h6">{{ errorMessage }}</p>-->
        <!--            </v-col>-->
        <!--          </v-row>-->
        <!--        </v-container>-->
        <!--      </v-card-text>-->
      </v-card>
    </v-dialog>


    <v-dialog v-model="keyWordsHelp" max-width="900px">
      <v-card>
        <v-card-title class="primary">
          <span class="headline">Dynamic Geo Location Parameters</span>
        </v-card-title>
        <!--        <v-card-actions>-->
        <!--          <v-spacer></v-spacer>-->
        <!--          <v-btn color="primary" text @click.stop="confirmPixel = false">Cancel</v-btn>-->
        <!--          <v-btn color="error" @click.stop="deletePixel" :loading="loadingDeletePixel">-->
        <!--            Delete-->
        <!--            <v-icon>mdi-delete</v-icon>-->
        <!--          </v-btn>-->
        <!--          <v-spacer></v-spacer>-->
        <!--        </v-card-actions>-->
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <p class="text-h6">Select the Dynamic Geo Location Parameters of your choice.</p>
                <p class="font-weight-medium">Available Parameters: {city}, {city_100K}, {city_200k}, {country}.
                  {country_code}, {metro}, {postal_code}, {state}, {state_name}.</p>

                <p class="text-h6">Examples:</p>
                <p>Hotels in {city} → Hotels in San Diego</p>
                <p>Hotels in {city_100k} → Hotels in San Mateo</p>
                <p>Hotels in {city_200k} → Hotels in Oxnard</p>
                <p>{country} Travel → United States Travel</p>
                <p>{country_code} Travel→ US Travel</p>
                <p>Hotels in {metro} → Hotels in Los Angeles</p>
                <p>SUVs near {postal_code} → SUVs near 90065</p>
                <p>{state} insurance→ CA Insurance</p>
                <p>{state_name} insurance → California Insurance</p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>


    <v-snackbar
        v-model="snackbar"
        :timeout="2500"
        right
        top
        color="green darken-2"
    >
      Copied : {{ snackbarText }}
    </v-snackbar>

    <v-card>

      <v-card-title class="primary" v-if="createNew">
        <span class="headline" ref="dialog">
          Create campaign: '{{ newCampaignName }}'
        </span>
      </v-card-title>
      <v-card-title class="primary" v-else>
        <span class="headline" ref="dialog">
          View campaign Id:
          <v-hover v-slot:default="{ hover }">
              <div :style="{ display: 'inline' }">
                {{ campaignId }}
                <v-icon small
                        :style="{visibility: hover ? 'visible': 'hidden'}"
                        @click.stop="copyToClipboard(campaignId.toString())">
                  mdi-content-copy
                </v-icon>
              </div>
            </v-hover> : '{{ campaignName }}'
        </span>
      </v-card-title>

      <v-progress-linear v-if="loading"
                         indeterminate
                         color="primary"
      ></v-progress-linear>
      <v-card-text>
        <v-tabs v-model="formTab">

          <v-tab href="#tab-info">
            <v-icon>mdi-information-variant</v-icon>
          </v-tab>

          <v-tab href="#tab-chart" v-if="!createNew">
            <v-icon>mdi-chart-bar</v-icon>
          </v-tab>

        </v-tabs>


        <v-tabs-items v-model="formTab">
          <v-form ref="form" v-model="valid">
            <v-tab-item value="tab-info">
              <v-container v-if="!loading">
                <v-row v-if="createNew">
                  <!--                <v-col cols="12" sm="2">-->
                  <!--                  <v-autocomplete-->
                  <!--                      v-model="country"-->
                  <!--                      :items="countries"-->
                  <!--                      hide-details-->
                  <!--                      dense-->
                  <!--                      solo-->
                  <!--                      item-value="code"-->
                  <!--                      @change="changeCountry"-->
                  <!--                      label="Country"-->
                  <!--                      clearable-->
                  <!--                  >-->
                  <!--                    <template v-slot:prepend>-->
                  <!--                      <flag :iso="country"/>-->
                  <!--                    </template>-->

                  <!--                    <template v-slot:item="{ item }">-->
                  <!--                      <flag :iso="item.code" class="mr-3"/>-->
                  <!--                      {{ item.text }}-->
                  <!--                    </template>-->

                  <!--                  </v-autocomplete>-->
                  <!--                </v-col>-->
                  <v-col cols="12">
                    <!--                  <v-autocomplete v-if="isTonicNetwork"-->
                    <!--                      v-model="offer"-->
                    <!--                      :items="offers"-->
                    <!--                      hide-details-->
                    <!--                      return-object-->
                    <!--                      dense-->
                    <!--                      item-value="id"-->
                    <!--                      item-text="name"-->
                    <!--                      label="Offer"-->
                    <!--                      clearable-->
                    <!--                  ></v-autocomplete>-->
                    <v-combobox
                        v-model="offer"
                        :items="offers"
                        hide-details
                        return-object
                        dense
                        item-value="id"
                        item-text="name"
                        label="Offer"
                        clearable
                    ></v-combobox>
                  </v-col>
                </v-row>
                <!--              <v-row v-if="!createNew && isTonicNetwork">-->
                <!--                <v-col cols="12" sm="12" class="title" justify="center" align="center">-->
                <!--                  <a :href="`https://publisher.tonic.com/privileged/display/details/${campaignId}?active=tracking`"-->
                <!--                     target="_blank"-->
                <!--                     style="text-decoration: none"-->
                <!--                     class="mr-4">-->
                <!--                    Tracking-->
                <!--                  </a>-->
                <!--                  <a :href="`https://publisher.tonic.com/privileged/display/details/${campaignId}?active=keywords`"-->
                <!--                     target="_blank"-->
                <!--                     style="text-decoration: none"-->
                <!--                     class="mr-4">-->
                <!--                    Keywords-->
                <!--                  </a>-->
                <!--                  <a :href="`https://publisher.tonic.com/privileged/display/details/${campaignId}?active=callbacks`"-->
                <!--                     target="_blank"-->
                <!--                     style="text-decoration: none"-->
                <!--                     class="mr-4">-->
                <!--                    Callbacks-->
                <!--                  </a>-->
                <!--                  <a :href="`https://publisher.tonic.com/privileged/display/details/${campaignId}?active=settings`"-->
                <!--                     target="_blank"-->
                <!--                     style="text-decoration: none"-->
                <!--                     class="mr-4">-->
                <!--                    Settings-->
                <!--                  </a>-->
                <!--                  <a :href="`https://publisher.tonic.com/privileged/display/details/${campaignId}?active=pixel`"-->
                <!--                     target="_blank"-->
                <!--                     style="text-decoration: none"-->
                <!--                     class="mr-4">-->
                <!--                    Pixel-->
                <!--                  </a>-->
                <!--                  <a :href="`https://publisher.tonic.com/privileged/display/details/${campaignId}?active=rename`"-->
                <!--                     target="_blank"-->
                <!--                     style="text-decoration: none"-->
                <!--                     class="mr-4">-->
                <!--                    Rename-->
                <!--                  </a>-->
                <!--                </v-col>-->
                <!--              </v-row>-->
                <v-row>
                  <v-col cols="12" sm="3">
                    <v-autocomplete
                        v-model="user"
                        :items="users"
                        hide-details
                        label="User"
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="12" sm="3">
                    <v-text-field
                        label="Offer name"
                        hide-details
                        v-model="name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <v-autocomplete
                        :items="countries"
                        v-model="country"
                        hide-details
                        item-value="code"
                        label="Country"
                    >
                      <template v-slot:prepend>
                        <flag :iso="country"/>
                      </template>
                      <template v-slot:item="{ item }">
                        <flag :iso="item.code" class="mr-3"/>
                        {{ item.text }}
                      </template>

                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <v-text-field
                        label="Postfix"
                        v-model="postfix"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col justify="center" align="center">
                    <span v-if="createNew" class="subtitle-1 mr-3">Result campaign Name :</span>
                    <span v-if="!createNew" class="subtitle-1 mr-3">Set new name:</span>
                    <span class="text-h5">'{{ newCampaignName }}'</span>
                    <v-btn v-if="!createNew" color="green darken-1" class="ml-3" :disabled="!isAllowUpdateName()"
                           :loading="loadingUpdateName"
                           @click="updateName()">Save
                      <v-icon>mdi-content-save-outline</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row v-if="!createNew">
                  <v-col align="center" cols="2">
                    <v-checkbox color="green darken-1"
                                v-model="imprint"
                                readonly
                                label="Imprint"
                    >
                    </v-checkbox>
                  </v-col>
                </v-row>
                <v-row v-if="createNew">
                  <!--                <v-col cols="1">-->
                  <!--                  <v-switch v-model="enableDomain"></v-switch>-->
                  <!--                </v-col>-->

                  <v-col cols="3">
                    <v-select
                        :items="routing_domains"
                        label="Routing Domains"
                        disabled
                        v-model="routing_domain"
                        return-object
                        item-text="name"
                        item-value="id"
                    ></v-select>
                  </v-col>
                  <v-col align="center" cols="2" offset="2">
                    <v-switch color="green darken-1"
                              v-model="imprint"
                              label="Imprint"
                    >
                    </v-switch>
                  </v-col>

                  <v-col cols="12">
                    <p class="font-weight-medium">Search for an available domain to associate with your campaign.</p>
                    <p class="font-italic">Note that if your desired domain is available for purchase no additional
                      suggestions will be displayed.</p>
                    <p class="red--text">Please do not acquire any trademark domain names. Any trademarked domains
                      will be required to be changed.</p>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                        class="mb-0 mt-5"
                        dense
                        label="Domain"
                        v-model="domain"
                        :rules="[validateDomain]"
                        @input="onInputDomain"
                    ></v-text-field>
                    <br>
                    <!--                  <p v-if="enableDomain"><b>You can now suggest a domain for your campaign. For more information, see-->
                    <!--                    info box below.</b></p>-->
                    <!--                  <p v-if="enableDomain">By using our self-registration process, you confirm that you understand and-->
                    <!--                    agree to comply with-->
                    <!--                    all the requirements listed below:-->
                    <!--                    <ul>-->
                    <!--                      <li>The domain should not include any words, brand-names, acronyms or character combinations that-->
                    <!--                        could be considered to infringe any trademark or result in any copyright complaints.-->
                    <!--                      </li>-->
                    <!--                      <li>The domain should be comprised of terms and real words that relate directly to the chosen-->
                    <!--                        offer, geo and adtitle/call to action (CTA) used.-->
                    <!--                      </li>-->
                    <!--                      <li>The domain should not include any numbers, special characters i.e. accents, or any-->
                    <!--                        incomprehensible letter combinationsWe would recommend that the domain is written in the same-->
                    <!--                        language as the adtitle or CTA.-->
                    <!--                      </li>-->
                    <!--                      <li>The domain should not include any special characters i.e. accents.</li>-->
                    <!--                      <li>The domain may include hyphens "-" which are contained within the domain name (not at the-->
                    <!--                        beginning or end).-->
                    <!--                      </li>-->
                    <!--                    </ul>-->
                    <!--                  </p>-->

                  </v-col>
                  <v-col cols="2">
                    <v-select
                        dense
                        v-model="tlds"
                        :items="available_tlds"
                        label="TLDS"
                        multiple
                        chips
                        :disabled="available_tlds.length === 1"
                        :rules="[validateTLDS]"
                    ></v-select>
                  </v-col>
                  <v-col cols="4">
                    <v-btn
                        v-if="(!domainIsValid && !domainIsNotValid)"
                        :disabled="!isReadyFroCheckDomain" color="primary"
                        @click="onCheckDomain">Check Domain Availability
                    </v-btn>

                    <p v-if="domainIsValid" class="green--text text-h6 pl-12 pt-3">Domain is available</p>
                    <span v-if="domainIsNotValid">
                      <span class="red--text text-h7 pt-5 pr-3">Select another Domain or select from</span><br>
                    </span>

                    <v-dialog max-width="900px" v-if="domainIsNotValid" v-model="suggestionsMenu"
                              :persistent="suggestionsListLoading">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs"
                            v-on="on"
                            small
                            @click="onClickSuggestions"
                        >Suggestions
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title class="primary">
                          <span class="headline">Suggestions domains</span>
                        </v-card-title>
                        <!--        <v-card-actions>-->
                        <!--          <v-spacer></v-spacer>-->
                        <!--          <v-btn color="primary" text @click.stop="confirmPixel = false">Cancel</v-btn>-->
                        <!--          <v-btn color="error" @click.stop="deletePixel" :loading="loadingDeletePixel">-->
                        <!--            Delete-->
                        <!--            <v-icon>mdi-delete</v-icon>-->
                        <!--          </v-btn>-->
                        <!--          <v-spacer></v-spacer>-->
                        <!--        </v-card-actions>-->
                        <v-card-text>
                          <v-container>
                            <v-data-table
                                :headers="headers_suggest_list"
                                :items="suggest_list"
                                :items-per-page="100"
                                class="elevation-1"
                                :loading="suggestionsListLoading"
                                hide-default-footer
                            >
                              <template v-slot:item.select="{ item }">
                                <v-btn @click="() => onSelectedDomain( item.domain)">Select domain</v-btn>
                              </template>
                            </v-data-table>
                          </v-container>
                        </v-card-text>
                      </v-card>
                    </v-dialog>

                  </v-col>
                </v-row>


                <!--              <v-row v-if="createNew && isTonicNetwork">-->
                <!--                <v-col cols="2">-->
                <!--                  <v-switch color="green darken-1" v-model="imprint" label="Imprint"></v-switch>-->
                <!--                </v-col>-->
                <!--                <v-col cols="10"  class="mt-4">-->
                <!--                  <p class="subtitle-1">-->
                <!--                    Default is <b>yes</b> for EU countries and <b>no</b> for non-EU countries.-->
                <!--                  </p>-->
                <!--                </v-col>-->
                <!--              </v-row>-->
                <v-row v-if="!createNew">
                  <v-col justify="center" align="center" class="title pa-0 ma-0">Keyword Settings</v-col>
                </v-row>
                <v-row v-if="!createNew">
                  <v-col class="pl-0 ml-0">
                    <b></b><br>
                    This optional feature allows you to use keywords of your choice.<br>

                    Should you wish to insert your keywords, we recommend the dynamic location parameters for city,
                    state
                    and country, especially in cases where users expect to see a location. Our system dynamically
                    inserts
                    the respective phrases based on the user's location.<br>

                    Available are the following location parameters: <b>{City}</b>, <b>{in City}</b>, <b>{Country}</b>,
                    <b>{in Country}</b>, <b>{State}</b>
                    and <b>{in State}</b>.<br>

                    <b>Examples:</b><br>
                    Car Vendors Near <b>{City}</b> -> Car Vendors Near New York<br>
                    Assisted Living Facilities <b>{in State}</b> -> Assisted Living Facilities In California<br>
                    Assisted Living Facilities <b>{in Country}</b> -> Assisted Living Facilities In Spain<br>
                    <br>
                    <span class="title">Please note: keyword_amount must use between 3 and 10 keywords. If no keyword_amount was provided, we automatically fill 6 keywords.</span>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" justify="center" align="center" class="title mt-2">Keywords</v-col>
                  <v-col cols="12">
                    <p>
                      You can select or input lander keywords. If none are selected the provider will choose relevant
                      keywords. <span :style="{ 'cursor': 'pointer' }" @click="keyWordsHelp = true"
                                      class="primary--text">Dynamic Geo Location Parameters</span>.
                    </p>
                  </v-col>
                </v-row>
                <v-row v-if="createNew">
                  <v-col cols="12" sm="5" justify="center" align="center" class="title mt-2">Keywords</v-col>
                  <v-col cols="12" sm="2">
                    <v-select
                        v-model="keywordsAmount"
                        :items="[0,1,2,3,4,5,6,7,8,9,10]"
                        hide-details
                        :menu-props='{maxHeight: 608}'
                        solo
                        label="Amount"
                        @change="onChangeKeywordsAmount"
                    ></v-select>
                  </v-col>

                </v-row>
                <v-row v-if="createNew || keyWordsReady">
                  <v-col cols="12" sm="7" class="pt-0 mt-0">
                    <v-row v-for="(item, i) in keyWords" :key="item + i + ''">
                      <v-text-field v-if="i <= 2"
                                    dense
                                    v-model="keyWords[i].value"

                                    counter
                                    :disabled="keyWords[i].delete"

                      ></v-text-field>
                      <v-text-field
                          v-if="i>2"
                          dense
                          v-model="keyWords[i].value"
                          :append-outer-icon="!keyWords[i].delete ?   'mdi-delete': null "
                          counter
                          :disabled="keyWords[i].delete"
                          @click:append-outer="onDeleteKeywordRow(i)"
                      ></v-text-field>
                      <v-btn icon color="primary"
                             class="pl-2"
                             v-if="keyWords[i].delete"
                             @click="onUndoKeywordRow(i)">
                        <v-icon dark>mdi-undo</v-icon>
                      </v-btn>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row v-if="!createNew && keyWords.length - keyWords.filter(f => f.delete).length < 10">
                  <v-col cols="12" sm="6" class="pt-0 mt-0 mr-0 ml-4" align="right">
                    <v-btn icon color="primary"
                           @click="onAddNewKeywordRow">
                      <v-icon dark>mdi-plus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <!--                                <v-row v-if="createNew">-->
                <!--                                  <v-col justify="center" align="center" class="pt-0 mt-0">-->
                <!--                                    <v-btn color="green darken-1" :disabled="!isAllowCreateNewCampaign()" @click="createNewCampaign()">-->
                <!--                                      Create campaign-->
                <!--                                      <v-icon>mdi-content-save-outline</v-icon>-->
                <!--                                    </v-btn>-->
                <!--                                  </v-col>-->
                <!--                                </v-row>-->
                <v-row v-if="!createNew">
                  <v-col justify="center" align="center" class="pt-0 mt-0">
                    <v-btn color="green darken-1" :disabled="isAllowUpdateKeywords" @click="updateKeywords()"
                           :loading="loadingKeywords">Update
                      Keywords
                      <v-icon>mdi-content-save-outline</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>

                <v-row v-if="createNew">
                  <v-col
                      cols="12"
                      justify="center" align="center"
                  >
                    <p></p>
                    <p class="title pa-0 ma-0">Pixels Settings</p>

                    <v-btn-toggle
                        :mandatory="isMandatory"
                        borderless
                        v-model="pixelType"
                        color="primary"

                    >
                      <v-btn value="facebook" :style="{'text-transform':'none !important'}"
                             :disabled="pixelsAllowDelete">
                        Facebook
                      </v-btn>
                      <v-btn value="tiktok" :style="{'text-transform':'none !important'}" :disabled="pixelsAllowDelete">
                        TikTok
                      </v-btn>

                    </v-btn-toggle>
                  </v-col>
                </v-row>

                <v-row v-if="pixelType === 'facebook' && pixelsIsReady">
                  <!--                  <v-col-->
                  <!--                      class="d-flex"-->
                  <!--                      cols="12"-->
                  <!--                      sm="3"-->
                  <!--                  >-->
                  <!--                    <v-select-->
                  <!--                        :items="facebook_events_name"-->
                  <!--                        v-model="pixels.event_name"-->
                  <!--                        label="Event Type"-->
                  <!--                    ></v-select>-->
                  <!--                  </v-col>-->

                  <v-col
                      class="d-flex"
                      cols="12"
                      sm="3"
                  >
                    <v-text-field
                        v-model="pixels.pixel_id"
                        label="Pixel Id"
                        :rules="[validatePixel]"
                    ></v-text-field>
                  </v-col>
                  <!--                  <v-col-->
                  <!--                      class="d-flex"-->
                  <!--                      cols="12"-->
                  <!--                      sm="6"-->
                  <!--                  >-->
                  <!--                    <v-text-field-->
                  <!--                        v-model="pixels.domain_verification"-->
                  <!--                        label="Domain Verification"-->
                  <!--                    ></v-text-field>-->
                  <!--                  </v-col>-->

                  <!--                  <v-col-->
                  <!--                      class="d-flex"-->
                  <!--                      cols="12"-->
                  <!--                      sm="12"-->
                  <!--                  >-->
                  <!--                    <v-text-field-->
                  <!--                        v-model="pixels.access_token"-->
                  <!--                        label="Access Token"-->
                  <!--                    ></v-text-field>-->
                  <!--                  </v-col>-->

                  <!--                  <v-col-->
                  <!--                      class="d-flex"-->
                  <!--                      cols="12"-->
                  <!--                      sm="12"-->
                  <!--                  >-->
                  <!--                    <v-row>-->
                  <!--                      <v-col cols="12"-->
                  <!--                             sm="6">-->
                  <!--                        <v-switch-->
                  <!--                            v-model="pixels.send_revenue"-->
                  <!--                            :false-value="'no'"-->
                  <!--                            :true-value="'yes'"-->
                  <!--                            label="Send revenue pre-estimations in pixel"-->
                  <!--                        ></v-switch>-->
                  <!--                      </v-col>-->
                  <!--                      <v-col cols="12"-->
                  <!--                             sm="6">-->
                  <!--                        The campaign needs to get some traffic for a few days to get revenue values in the pixel. Please-->
                  <!--                        note that this will differ from the estimations later.-->
                  <!--                      </v-col>-->
                  <!--                    </v-row>-->

                  <!--                  </v-col>-->

                  <!--                  <v-col-->
                  <!--                      class="d-flex"-->
                  <!--                      cols="12"-->
                  <!--                      sm="12"-->
                  <!--                  >-->
                  <!--                    <v-row>-->
                  <!--                      <v-col cols="12"-->
                  <!--                             sm="6">-->
                  <!--                        <v-select-->
                  <!--                            :items="facebook_deduplication_parameter"-->
                  <!--                            v-model="pixels.deduplication_parameter"-->
                  <!--                            label="Event Deduplication parameter"-->
                  <!--                        ></v-select>-->
                  <!--                      </v-col>-->
                  <!--                      <v-col cols="12"-->
                  <!--                             sm="6">-->
                  <!--                        <p>Only necessary for publishers using the Facebook Conversion API</p>-->
                  <!--                        <ul>-->
                  <!--                          <li>Facebook pixel is fired when a visitor makes an ad-click</li>-->
                  <!--                          <li>In order to track all the events, even in the case where a visitor has blocked Facebook,-->
                  <!--                            you-->
                  <!--                            can upload events through the Facebook Business Manager-->
                  <!--                          </li>-->
                  <!--                          <li>To avoid having duplicate events, Facebook checks the deduplication parameter against-->
                  <!--                            browser conversion events. If these match, the conversion is not counted twice.-->
                  <!--                          </li>-->
                  <!--                          <li>The default value is random_id which assigns a random id to each click tracked through the-->
                  <!--                            browser-->
                  <!--                          </li>-->
                  <!--                        </ul>-->

                  <!--                      </v-col>-->
                  <!--                    </v-row>-->
                  <!--                  </v-col>-->
                </v-row>

                <v-row v-if="pixelType === 'tiktok' && pixelsIsReady">
                  <!--                  <v-col-->
                  <!--                      class="d-flex"-->
                  <!--                      cols="12"-->
                  <!--                      sm="3"-->
                  <!--                  >-->
                  <!--                    <v-select-->
                  <!--                        v-if="pixels.events_s2s_conversion === 'no'"-->
                  <!--                        :items="tiktik_events_name"-->
                  <!--                        v-model="pixels.event_name"-->
                  <!--                        label="Event Type"-->
                  <!--                    ></v-select>-->
                  <!--                  </v-col>-->

                  <v-col
                      class="d-flex"
                      cols="12"
                      sm="3"
                  >
                    <v-text-field
                        v-model="pixels.pixel_id"
                        label="Pixel Id"
                        :rules="[validatePixel]"
                    ></v-text-field>
                  </v-col>
                  <!--                <v-col-->
                  <!--                    class="d-flex"-->
                  <!--                    cols="12"-->
                  <!--                    sm="6"-->
                  <!--                >-->
                  <!--                  <v-text-field-->
                  <!--                      v-model="pixels.domain_verification"-->
                  <!--                      label="Domain Verification"-->
                  <!--                  ></v-text-field>-->
                  <!--                </v-col>-->

                  <!--                  <v-col-->
                  <!--                      class="d-flex"-->
                  <!--                      cols="12"-->
                  <!--                      sm="6"-->
                  <!--                  >-->
                  <!--                    <v-text-field-->
                  <!--                        v-model="pixels.access_token"-->
                  <!--                        label="Access Token"-->
                  <!--                    ></v-text-field>-->
                  <!--                  </v-col>-->

                  <!--                  <v-col-->
                  <!--                      class="d-flex"-->
                  <!--                      cols="12"-->
                  <!--                      sm="12"-->
                  <!--                  >-->
                  <!--                    <v-row>-->
                  <!--                      <v-col cols="12"-->
                  <!--                             sm="6">-->
                  <!--                        <v-switch-->
                  <!--                            v-model="pixels.send_revenue"-->
                  <!--                            :false-value="'no'"-->
                  <!--                            :true-value="'yes'"-->
                  <!--                            label="Send revenue pre-estimations in pixel"-->
                  <!--                        ></v-switch>-->
                  <!--                      </v-col>-->
                  <!--                      <v-col cols="12"-->
                  <!--                             sm="6">-->
                  <!--                        The campaign needs to get some traffic for a few days to get revenue values in the pixel. Please-->
                  <!--                        note that this will differ from the estimations later.-->
                  <!--                      </v-col>-->
                  <!--                    </v-row>-->

                  <!--                  </v-col>-->

                  <!--                  <v-col-->
                  <!--                      class="d-flex"-->
                  <!--                      cols="12"-->
                  <!--                      sm="12"-->
                  <!--                  >-->
                  <!--                    <v-row>-->
                  <!--                      <v-col cols="12"-->
                  <!--                             sm="6">-->
                  <!--                        <v-switch-->
                  <!--                            v-model="pixels.events_s2s_conversion"-->
                  <!--                            :false-value="'no'"-->
                  <!--                            :true-value="'yes'"-->
                  <!--                            label="Use new TikTok S2S conversions with default mappings."-->
                  <!--                        ></v-switch>-->
                  <!--                      </v-col>-->
                  <!--                      <v-col cols="12"-->
                  <!--                             sm="6">-->
                  <!--                        <b>Event mapping:</b><br>-->
                  <!--                        We are now sending 4 events to TikTok, one for each track event:<br>-->
                  <!--                        <ul>-->
                  <!--                          <li>Redirect → PageView</li>-->
                  <!--                          <li>View → ClickButton</li>-->
                  <!--                          <li>Keyword Click → ViewContent</li>-->
                  <!--                          <li>Ad Click → CompletePayment</li>-->
                  <!--                        </ul>-->
                  <!--                      </v-col>-->
                  <!--                    </v-row>-->

                  <!--                  </v-col>-->

                  <!--                  <v-col-->
                  <!--                      class="d-flex"-->
                  <!--                      cols="12"-->
                  <!--                      sm="12"-->
                  <!--                  >-->
                  <!--                    <v-row>-->
                  <!--                      <v-col cols="12"-->
                  <!--                             sm="12">-->

                  <!--                        <b>Important:</b><br>-->
                  <!--                        You need to append <b>ttclid=__CLICKID__</b> to the end of your URL and insert it at Ad Group->-->
                  <!--                        Ad-->
                  <!--                        Details-> URL in TikTok ads. (See <a-->
                  <!--                          href="https://bytedance.sg.feishu.cn/docx/doxcnBh35YXOUaBY3cr7bnPoLug">ads.tiktok.com</a>.)<br>-->
                  <!--                        <b>How to use the TikTok Pixel:</b><br>-->
                  <!--                        Sign in to your TikTok Ads Manager to gather the values that you will use to complete the tag-->
                  <!--                        setup:<br>-->
                  <!--                        <ul>-->
                  <!--                          <li>In your TikTok Ads Manager Account, go to Assets -> Events</li>-->
                  <!--                          <li>Click the Manage button for Web Events</li>-->
                  <!--                          <li>If not already done create a Pixel:</li>-->
                  <!--                          <ol>-->
                  <!--                            <li>Select the Set Up Web Events button</li>-->
                  <!--                            <li>Type in a name</li>-->
                  <!--                            <li>Select Events API as Connection Method and click Next</li>-->
                  <!--                            <li>Select Manually Set Up Events API as Installation Type and click Next</li>-->
                  <!--                            <li>Select the Go to Settings button in the bottom right corner</li>-->
                  <!--                          </ol>-->
                  <!--                          <li>If you have a Pixel:</li>-->
                  <!--                          <ol>-->
                  <!--                            <li>Click on the pixel name</li>-->
                  <!--                            <li>Go to the Settings tab</li>-->
                  <!--                          </ol>-->
                  <!--                          <li>Copy the ID which is written underneath the pixel name and past it as Pixel Id</li>-->
                  <!--                          <li>Click the Generate Access Token button under Events API</li>-->
                  <!--                          <li>Copy the token and past it as Access Token</li>-->
                  <!--                        </ul>-->
                  <!--                      </v-col>-->
                  <!--                    </v-row>-->
                  <!--                  </v-col>-->

                </v-row>

                <!--                <v-row v-if="pixelsIsReady">-->
                <!--                  <v-col v-if="pixelsAllowDelete">-->
                <!--                    <v-col justify="center" align="right">-->
                <!--                      <v-btn color="red darken-1 mr-3"-->
                <!--                             @click.stop="confirmPixel = true">-->
                <!--                        Delete-->
                <!--                        <v-icon>mdi-delete</v-icon>-->
                <!--                      </v-btn>-->
                <!--                      <v-btn-->
                <!--                          color="green darken-1"-->
                <!--                          :disabled="!allowUpdatePixel"-->
                <!--                          :loading="loadingUpdatePixel"-->
                <!--                          @click.stop="updatePixel"-->
                <!--                      >-->
                <!--                        Update-->
                <!--                        <v-icon>mdi-content-save</v-icon>-->
                <!--                      </v-btn>-->
                <!--                    </v-col>-->
                <!--                  </v-col>-->

                <!--                  <v-col v-if="!createNew && pixelIsNew">-->
                <!--                    <v-col justify="center" align="right" class="pt-0 mt-0">-->
                <!--                      <v-btn-->
                <!--                          color="green darken-1 ml-3"-->
                <!--                          :disabled="!allowCreatePixel"-->
                <!--                          @click.stop="createPixel"-->
                <!--                          :loading="loadingCreatePixel"-->
                <!--                      >-->
                <!--                        Save-->
                <!--                        <v-icon>mdi-content-save</v-icon>-->
                <!--                      </v-btn>-->
                <!--                    </v-col>-->
                <!--                  </v-col>-->


                <!--                <v-col cols="12">-->
                <!--                  <pre>{{ JSON.stringify(pixels, null, 2) }}</pre>-->
                <!--                </v-col>-->
                <!--                </v-row>-->

                <v-row v-if="createNew">
                  <v-col justify="center" align="center" class="pt-0 mt-0">
                    <v-btn color="green darken-1" :disabled="!isAllowCreateNewCampaign()" @click="createNewCampaign()"
                           :loading="loadingCreateCampaign">
                      Create campaign
                      <v-icon>mdi-content-save-outline</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>

              </v-container>

            </v-tab-item>

            <v-tab-item value="tab-chart">
              <v-container v-if="statisticIsReady">
                <v-row>
                  <v-col cols="12" sm="12">
                    <highcharts class="stock" :options="highchartsOptions"></highcharts>
                  </v-col>
                </v-row>
                <v-col cols="12" sm="12">
                  <v-data-table
                      hide-default-footer
                      disable-pagination
                      disable-sort
                      :headers="headers"
                      :items="stat"
                      item-key="date"
                  >
                  </v-data-table>
                </v-col>
              </v-container>
            </v-tab-item>
          </v-form>
        </v-tabs-items>

      </v-card-text>
    </v-card>


  </v-dialog>
</template>

<script>
import Highcharts from 'highcharts'
import {DateTime} from 'luxon'
import api from '@/utils/api'

export default {
  name: 'CampaignEditor',
  props: {
    value: {
      default: false
    },
    state: {
      default: ''
    },
    users: {
      default: []
    },
    countries: {
      default: []
    },
    offers: {
      default: []
    },
    selectedCountry: null,
    campaign: {
      default: {
        clicks: 0,
        country: "",
        cpc: 0,
        id: 0,
        imprint: "no",
        link: "",
        name: "",
        offer: "",
        offer_id: "",
        revenue: 0,
        target: "",
        type: "",
        user: "",
        vertical: ""
      }
    },
    tz: {
      default: ''
    },

    tab: {
      default: 'tab-info'
    }
  },
  data() {
    return {
      createNew: false,
      isVisibleErrorDialog: false,
      errorMessage: '',

      snackbar: false,
      snackbarText: '',

      loading: false,

      formTab: this.tab,

      user: null,
      name: '',
      country: null,
      postfix: '',
      imprint: false,

      domain: '',
      domainIsReadyForCheck: false,
      domainIsValid: false,
      domainIsNotValid: false,
      domainIsSelected: null,

      campaignId: 0,
      campaignName: '',
      newCampaignName: '',

      newUser: null,
      newName: null,
      newCountry: null,
      newPostfix: null,
      newImprint: null,
      dialog: this.value,


      keyWordsReady: false,
      keyWordsOrigin: [],
      keywordsAmount: 0,
      keyWords: [],
      confirmPixel: false,
      pixelType: null,
      pixelIsNew: false,
      pixelsNewFaceBook: {
        event_name: 'Purchase',
        pixel_id: null,
        domain_verification: null,
        access_token: null,
        send_revenue: 'no',
        deduplication_parameter: 'random_id'
      },

      pixelsNewTikTok: {
        event_name: 'CompletePayment',
        pixel_id: '',
        send_revenue: 'no',
        access_token: '',
        events_s2s_conversion: 'no'
      },
      pixels: {},
      pixelsFromApi: {},
      pixelsIsReady: false,
      pixelsAllowDelete: false,
      pixelsAllowReset: false,
      loadingCreatePixel: false,
      loadingUpdatePixel: false,
      loadingDeletePixel: false,


      loadingUpdateName: false,
      loadingImprint: false,
      loadingKeywords: false,
      loadingCreateCampaign: false,

      facebook_events_name: [
        'AddPaymentInfo',
        'AddToCart',
        'AddToWhitelist',
        'CompleteRegistration',
        'Contact',
        'CustomizeProduct',
        'Donate',
        'FindLocation',
        'InitiateCheckout',
        'Lead',
        'PageView',
        'Purchase',
        'Schedule',
        'Search',
        'StartTrial',
        'SubmitApplication',
        'Subscribe',
        'ViewContent'
      ],

      facebook_deduplication_parameter: [
        'random_id',
        'track_id',
        'subid1',
        'subid2',
        'subid3',
        'subid4'
      ],

      tiktik_events_name: [
        "AddPaymentInfo",
        "AddToCart",
        "AddToWishlist",
        "ClickButton",
        "CompletePayment",
        "CompleteRegistration",
        "Contact",
        "Download",
        "InitiateCheckout",
        "PlaceAnOrder",
        "Search",
        "SubmitForm",
        "Subscribe",
        "ViewContent"
      ],

      statisticIsReady: false,

      offer: null,

      highchartsOptions: {
        chart: {
          zoomType: 'xy'
        },
        credits: {
          enabled: false
        },
        title: null,
        subtitle: null,
        xAxis: [{
          categories: [],
          crosshair: true
        }],
        yAxis: [{ // Primary yAxis
          labels: {
            format: '{value}',
            style: {
              color: Highcharts.getOptions().colors[1]
            }
          },
          title: {
            text: 'Clicks',
            style: {
              color: Highcharts.getOptions().colors[1]
            }
          }
        }, { // Secondary yAxis
          title: {
            text: 'Revenue',
            style: {
              color: Highcharts.getOptions().colors[0]
            }
          },
          labels: {
            format: '{value} $',
            style: {
              color: Highcharts.getOptions().colors[0]
            }
          },
          opposite: true
        }],
        tooltip: {
          shared: true
        },
        legend: {
          align: 'left',
          x: 80,
          verticalAlign: 'top',
          y: 80,
          floating: true,
          backgroundColor:
              Highcharts.defaultOptions.legend.backgroundColor || // theme
              'rgba(255,255,255,0.25)'
        },
        series: [
          {
            name: 'Revenue',
            type: 'column',
            yAxis: 1,
            // pointInterval: 1000 * 3600 * 24,
            // data: [27.6, 28.8, 21.7, 34.1, 29.0, 28.4, 45.6, 51.7, 39.0, 60.0, 28.6, 32.1],
            data: [],
            tooltip: {
              valueSuffix: ' $'
            }

          },
          {
            name: 'Clicks',
            type: 'spline',
            // pointInterval: 1000 * 3600 * 24,
            data: [],
            tooltip: {
              valueSuffix: ''
            }
          }]
      },
      stat: [],
      headers: [
        {text: 'Date', align: 'start', value: 'date', class: 'sticky-header', sortable: false},
        {text: 'Clicks', align: 'start', value: 'clicks', class: 'sticky-header', sortable: false},
        {text: 'Revenue', align: 'start', value: 'revenue', class: 'sticky-header', sortable: false},
        {text: 'CPC', align: 'start', value: 'cpc', class: 'sticky-header', sortable: false}
      ],


      routing_domain: null,
      routing_domains: [],

      available_tlds: [],
      tlds: null,
      valid: false,

      keyWordsHelp: false,
      suggestionsMenu: false,

      passthrough_params: [],

      headers_suggest_list: [
        {text: 'Domain', align: 'center', value: 'domain', class: 'sticky-header', sortable: false},
        {text: '', align: 'center', value: 'select', class: 'sticky-header', sortable: false}
      ],

      suggestionsListLoading: false,

      suggest_list: [],
      // suggest_list: [
      //   {domain: "ssssss.today", select: 'true'},
      //   {domain: "ssssssproperties.today", select: 'true'},
      //   {domain: "sssssssystems.today", select: 'true'},
      //   {domain: "ssssssacademy.today", select: 'true'},
      //   {domain: "ssssssreviews.today", select: 'true'},
      //   {domain: "ssssssnetwork.today", select: 'true'},
      //   {domain: "sssssssolutions.today", select: 'true'},
      //   {domain: "sssssscenter.today", select: 'true'},
      //   {domain: "ssssssstudio.today", select: 'true'},
      //   {domain: "ssssssmoney.today", select: 'true'},
      //   {domain: "ssssssspace.today", select: 'true'},
      //   {domain: "ssssssguide.today", select: 'true'},
      //   {domain: "ssssssnews.today", select: 'true'},
      //   {domain: "ssssssglobal.today", select: 'true'},
      //   {domain: "ssssssstar.today", select: 'true'},
      //   {domain: "sssssscity.today", select: 'true'},
      //   {domain: "ssssssworld.today", select: 'true'},
      //   {domain: "sssssshome.today", select: 'true'},
      //   {domain: "ssssssgroup.today", select: 'true'},
      //   {domain: "sssssslife.today", select: 'true'},
      //   {domain: "ssssssmart.today", select: 'true'},
      //   {domain: "ssssssmail.today", select: 'true'},
      //   {domain: "sssssstech.today", select: 'true'},
      //   {domain: "ssssssonline.today", select: 'true'},
      //   {domain: "sssssszone.today", select: 'true'},
      //   {domain: "ssssssart.today", select: 'true'},
      //   {domain: "ssssssfit.today", select: 'true'},
      //   {domain: "ssssssshop.today", select: 'true'},
      //   {domain: "ssssssbox.today", select: 'true'},
      //   {domain: "sssssslab.today", select: 'true'},
      //   {domain: "sssssshub.today", select: 'true'},
      //   {domain: "ssssssweb.today", select: 'true'},
      //   {domain: "ssssssnow.today", select: 'true'},
      //   {domain: "sssssspro.today", select: 'true'},
      //   {domain: "sssss.today", select: 'true'},
      //   {domain: "thessssss.today", select: 'true'},
      //   {domain: "aaa2.today", select: 'true'},
      //   {domain: "ssssssgenius.today", select: 'true'},
      //   {domain: "ssssssspark.today", select: 'true'},
      //   {domain: "ssssssboost.today", select: 'true'},
      //   {domain: "klkse.today", select: 'true'},
      //   {domain: "ssssssprime.today", select: 'true'},
      //   {domain: "sssssscom.today", select: 'true'},
      //   {domain: "ssssssify.today", select: 'true'},
      //   {domain: "ssssssss.today", select: 'true'},
      //   {domain: "ssssssco.today", select: 'true'},
      //   {domain: "zzzzzz.today", select: 'true'},
      //   {domain: "ssssssio.today", select: 'true'},
      //   {domain: "sssssss.today", select: 'true'},
      //   {domain: "fffs.today", select: 'true'},
      //   {domain: "eees.today", select: 'true'},
      //   {domain: "ssww.today", select: 'true'},
      //   {domain: "ssssss2.today", select: 'true'},
      //   {domain: "haohuo.today", select: 'true'},
      //   {domain: "zzzz.today", select: 'true'},
      //   {domain: "thesssss.today", select: 'true'},
      //   {domain: "sssses.today", select: 'true'},
      //   {domain: "xxxx.today", select: 'true'}
      // ]
    }
  },
  created() {
    // console.log(this.createNew)
    if (this.campaign === null) {
      this.createNew = true
      this.formTab = 'tab-info'
      this.onChangeKeywordsAmount()
    } else {
      this.campaignId = this.campaign.id
      this.campaignName = this.campaign.name
      this.imprint = this.campaign.imprint === 'yes'

      this.getKeywordsInfo()
      this.parseName()
      this.loadStatistic()
    }

    if (this.isMandatory) {
      this.pixelType = 'facebook'
    }
  },
  async mounted() {
    if (this.createNew) {
      this.loading = true
      try {
        let {data} = await api.affNetworkGetCampaignWizard({
          network: this.$route.params.network,
          account: this.$route.params.account,
        })
        console.log(data)
        this.available_tlds = data.available_tlds
        this.routing_domains = data.routing_domains
        this.routing_domain = this.routing_domains.find((d) => Object.prototype.hasOwnProperty.call(d, 'default_domain'))
        this.passthrough_params = data.passthrough_params.map(p => p.params).flat()
        if (Array.isArray(this.available_tlds) && this.available_tlds.length === 1) {
          this.tlds = [this.available_tlds[0]]
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
        this.$nextTick(() => this.$refs.form.validate())
      }
    }
  },

  computed: {
    isMandatory() {
      return this.$route.params.network === 'DomainActive' &&
          (this.$route.params.account === 'IdealMedia' ||
              this.$route.params.account === 'IdealMediaNative' )
    },
    isAllowUpdateKeywords() {
      if (this.keyWordsOrigin.length !== this.keyWords.length) {
        return false
      }
      if (this.keyWords.map((k, i) => this.keyWordsOrigin[i] !== k.value).some(v => v)) {
        return false
      }
      if (this.keyWords.some(k => k.new)) {
        return false
      }
      if (this.keyWords.some(k => k.delete)) {
        return false
      }
      return true
    },

    allowCreatePixel() {
      if (this.pixelType === 'facebook') {
        if (this.pixelsNewFaceBook.event_name !== null &&
            (this.pixelsNewFaceBook.pixel_id !== null && typeof this.pixelsNewFaceBook.pixel_id === "string" && this.pixelsNewFaceBook.pixel_id.length > 10) &&
            (this.pixelsNewFaceBook.access_token !== null && typeof this.pixelsNewFaceBook.access_token === "string" && this.pixelsNewFaceBook.access_token.length > 10) &&
            this.pixelsNewFaceBook.deduplication_parameter !== null
        ) {
          return true
        }
      }
      if (this.pixelType === 'tiktok') {
        if (
            (
                (this.pixelsNewTikTok.events_s2s_conversion === 'no' &&
                    (typeof this.pixelsNewTikTok.event_name === "string"
                        && this.pixelsNewTikTok.event_name.length > 0)) ||
                (this.pixelsNewTikTok.events_s2s_conversion === 'yes')
            ) &&
            (this.pixelsNewTikTok.pixel_id !== null && typeof this.pixelsNewTikTok.pixel_id === 'string' && this.pixelsNewTikTok.pixel_id.length > 10) &&
            (this.pixelsNewTikTok.access_token !== null && typeof this.pixelsNewTikTok.access_token === "string" && this.pixelsNewTikTok.access_token.length > 10) &&
            this.pixelsNewTikTok.send_revenue !== null
        ) {
          return true
        }
      }
      return false
    },
    allowUpdatePixel() {
      // debugger
      if (!this.pixelIsNew) {
        if (this.pixelType === 'facebook') {
          if (this.pixels.event_name !== this.pixelsFromApi.event_name ||
              this.pixels.pixel_id !== this.pixelsFromApi.pixel_id ||
              this.pixels.access_token !== this.pixelsFromApi.access_token ||
              this.pixels.deduplication_parameter !== this.pixelsFromApi.deduplication_parameter ||
              this.pixels.send_revenue !== this.pixelsFromApi.send_revenue ||
              this.pixels.domain_verification !== this.pixelsFromApi.domain_verification
          ) {
            return true
          }

        }

        if (this.pixelType === 'tiktok') {
          if (
              (
                  (this.pixels.events_s2s_conversion === 'no' &&
                      (typeof this.pixels.event_name === "string"
                          && this.pixels.event_name !== this.pixelsFromApi.event_name)) ||
                  (this.pixels.events_s2s_conversion === 'yes' &&
                      this.pixels.events_s2s_conversion !== this.pixelsFromApi.events_s2s_conversion)
              ) ||
              this.pixels.pixel_id !== this.pixelsFromApi.pixel_id ||
              this.pixels.access_token !== this.pixelsFromApi.access_token ||
              this.pixels.send_revenue !== this.pixelsFromApi.send_revenue
          ) {
            return true
          }
        }
      }

      return false
    },

    isReadyFroCheckDomain() {
      return this.domainIsReadyForCheck && (!this.domainIsValid && !this.domainIsNotValid)
    },
  },
  methods: {
    parseName() {
      const match = this.campaignName.match(/^(?<user>\w+)\s+-\s+(?<name>\w+)_(?<country>[a-zA-Z][a-zA-Z])\s?(?<postfix>.*)$/)
      if (match && match.length === 5) {
        this.user = match.groups.user
        this.name = match.groups.name
        this.country = match.groups.country
        this.postfix = match.groups.postfix
      }
    },

    loadStatistic() {
      // console.log(this.campaign)
      if (this.campaign.statistic) {

        this.campaign.statistic.days.buckets.forEach(b => {
          const date = DateTime.fromMillis(b.key, {zone: this.tz}).toSQLDate()
          this.highchartsOptions.xAxis[0].categories.push(date)

          const revenue = Math.round(b.revenue.value * 100) / 100

          this.highchartsOptions.series[0].data.push(revenue)
          this.highchartsOptions.series[1].data.push(b.clicks.value)

          const cpc = Math.round((revenue / b.clicks.value) * 100) / 100
          this.stat.push({
            date,
            clicks: b.clicks.value > 0 ? b.clicks.value : '',
            revenue: revenue > 0 ? revenue : '',
            cpc: cpc > 0 ? cpc : ''
          })
        })

        this.statisticIsReady = true
      }
    },

    async getKeywordsInfo() {
      this.loading = true
      this.keyWordsReady = false

      const request = {
        network: this.$route.params.network,
        account: this.$route.params.account,
        campaignId: this.campaignId
      }
      try {
        const resp = await api.affNetworkGetCampaignInfo(request)

        if (resp.data.keywords?.Keywords === 'null') {

          this.keyWordsOrigin = []
          this.keyWords = [1, 2, 3].map(() => ({
            value: '',
            delete: false
          }))

        } else if (Array.isArray(resp.data.keywords?.Keywords)) {

          this.keyWordsOrigin = resp.data.keywords.Keywords.slice()
          this.keyWords = resp.data.keywords.Keywords.map(k => ({
            value: k,
            delete: false
          }))
          const kwAmount = resp.data.keywords.KwAmount
          if (kwAmount !== this.keyWords.length) {
            if (kwAmount > this.keyWords.length)
              new Array(kwAmount - this.keyWords.length).fill(0).forEach(() => {
                this.keyWordsOrigin.push('')
                this.keyWords.push({
                  value: '',
                  delete: false
                })
              })
          }


        }

        this.keywordsAmount = this.keyWords.length
        this.keyWordsReady = true

        if (resp.data.pixels) {
          this.pixelType = resp.data.pixels.source
          this.pixelsFromApi = Object.assign({}, resp.data.pixels)
          this.pixels = Object.assign({}, resp.data.pixels)
          // if (this.pixels.send_revenue === 'yes') {
          //   this.pixels.send_revenue = true
          // } else {
          //   this.pixels.send_revenue = false
          // }
          // if (this.pixels.events_s2s_conversion === 'yes') {
          //   this.pixels.events_s2s_conversion = true
          // } else {
          //   this.pixels.events_s2s_conversion = false
          // }
          this.pixelsIsReady = true
          this.pixelsAllowDelete = true
          this.pixelsAllowReset = true
        }

      } catch (e) {
        console.error('getKeywordsInfo', e)

      }

      this.loading = false
    },

    async updateName() {
      // return
      // console.log('updateName', this.newCampaignName)
      this.loadingUpdateName = true
      try {
        const resp = await api.affNetworkUpdateName({
          network: this.$route.params.network,
          account: this.$route.params.account,
          id: this.campaignId,
          name: this.newCampaignName
        })
        console.log('updateName', resp)
        if (resp.data.error) {
          this.isVisibleErrorDialog = true
          this.errorMessage = resp.data.msg
        } else {
          this.campaignName = this.newCampaignName
          this.$emit('update', true)
        }
      } catch (e) {
        console.error('updateName', e)
        this.errorMessage = e
        this.isVisibleErrorDialog = true
      }
      this.loadingUpdateName = false
    },

    // async updateImprint() {
    //   return
    //   this.loadingImprint = true
    //   // console.log('updateImprint', this.imprint)
    //
    //   try {
    //     const resp = await api.affNetworkUpdateImprint({
    //       network: this.$route.params.network,
    //       account: this.$route.params.account,
    //       id: this.campaignId,
    //       name: this.campaignName,
    //       imprint: this.imprint ? 'yes' : 'no'
    //     })
    //     if (resp.data.error) {
    //       this.isVisibleErrorDialog = true
    //       this.errorMessage = resp.data.msg
    //       this.imprint = !this.imprint
    //       this.$emit('update', true)
    //     }
    //     console.log('updateImprint', resp)
    //   } catch (e) {
    //     console.log('updateImprint', e)
    //     this.errorMessage = e
    //     this.isVisibleErrorDialog = true
    //   }
    //
    //   this.loadingImprint = false
    // },

    async updateKeywords() {
      // return
      this.loadingKeywords = false
      // console.log('updateKeywords', this.keyWords)

      // console.log(keywords, keywordAmount)
      const {keywords, keywordAmount} = this.getKeywords()
      try {
        const resp = await api.affNetworkUpdateKeywords({
          network: this.$route.params.network,
          account: this.$route.params.account,
          id: this.campaignId,
          country: this.campaign.country,
          keywords,
          keywordAmount
        })
        if (resp.data.error) {
          this.isVisibleErrorDialog = true
          this.errorMessage = resp.data.msg
        } else {
          // console.log('updateKeywords 1', resp)
          this.$emit('update', true)
          await this.getKeywordsInfo()
        }
      } catch (e) {
        console.error('updateKeywords', e)
        this.errorMessage = e
        this.isVisibleErrorDialog = true
      }
    },


    getKeywords() {
      let keywordAmount = 0
      const keywords = this.keyWords.reduce((acc, item) => {
        if (!item.delete && typeof item.value === 'string' && item.value.length > 0) {
          acc.push(item.value)
          keywordAmount++
        }
        if (!item.delete && ((typeof item.value === 'string' && item.value.length === 0) || item.value === null)) {
          keywordAmount++
        }
        return acc
      }, [])

      return {keywordAmount, keywords}
    },


    async createNewCampaign() {
      this.loadingCreateCampaign = true
      if (this.tlds.length > 1) {
        console.error('this.tlds.length > 1', this.tlds.length)
        return
      }

      const r = {
        network: this.$route.params.network,
        account: this.$route.params.account,
        country: this.country,
        category: this.offer.name,
        offer_id: this.offer.id,
        domain: this.domain + '.' + this.tlds[0],
        name: this.newCampaignName,
        routing_domain: this.routing_domain.default_domain,
        https_enabled: true,
        use_domain_parking: true
      }

      if (this.imprint === true) {
        r.use_imprinting = true
      }

      const {keywords, keywordAmount} = this.getKeywords()

      if (keywordAmount > 0) {
        r.keywords = keywords
      }

      if (Array.isArray(this.passthrough_params) && this.passthrough_params.length > 0) {
        r.passthrough_parameters = this.passthrough_params
      }

      if (!(this.pixelType === null || this.pixelType === undefined) && this.pixelsIsReady) {
        r.pixel = {
          type: this.pixelType,
          id: this.pixels.pixel_id,
        }
      }

      try {
        const resp = await api.affNetworkCreateNewCampaign(r)

        if (resp.data.error) {
          this.isVisibleErrorDialog = true
          this.errorMessage = resp.data.msg
        } else {
          this.campaignName = this.newCampaignName
          this.$emit('created', {name: this.campaignName, id: resp.data.data.campaign_id})
          this.dialog = false
        }
      } catch (e) {
        console.error('createNewCampaign', e)
        this.errorMessage = e
        this.isVisibleErrorDialog = true
      } finally {
        this.loadingCreateCampaign = false
      }
    },

    // async createPixel() {
    //   this.loadingCreatePixel = true
    //   const r = {
    //     network: this.$route.params.network,
    //     account: this.$route.params.account,
    //     id: this.campaignId,
    //     source: this.pixelType,
    //     pixel: this.pixels
    //   }
    //   try {
    //     const resp = await api.affNetworkCreatePixel(r)
    //     this.pixelsFromApi = r.pixel
    //     this.pixelIsNew = false
    //     this.pixelsAllowDelete = true
    //     this.pixelsAllowReset = true
    //     console.log('createPixel', resp)
    //   } catch (e) {
    //     console.error('createPixel', e)
    //     this.errorMessage = e
    //     this.isVisibleErrorDialog = true
    //   }
    //
    //   this.loadingCreatePixel = false
    // },

    // async updatePixel() {
    //   this.loadingUpdatePixel = true
    //   const r = {
    //     network: this.$route.params.network,
    //     account: this.$route.params.account,
    //     id: this.campaignId,
    //     source: this.pixelType,
    //     pixel: this.pixels
    //   }
    //   try {
    //     const resp = await api.affNetworkUpdatePixel(r)
    //     this.pixelsFromApi = r.pixel
    //     // if (this.pixels.send_revenue === 'yes') {
    //     //   this.pixels.send_revenue = true
    //     // } else {
    //     //   this.pixels.send_revenue = false
    //     // }
    //     console.log('updatePixel', resp)
    //   } catch (e) {
    //     console.error('updatePixel', e)
    //     this.errorMessage = e
    //     this.isVisibleErrorDialog = true
    //   }
    //
    //   this.loadingUpdatePixel = false
    // },


    // async deletePixel() {
    //   this.loadingDeletePixel = true
    //   const r = {
    //     network: this.$route.params.network,
    //     account: this.$route.params.account,
    //     id: this.campaignId
    //   }
    //   try {
    //     const resp = await api.affNetworkDeletePixel(r)
    //     console.log('deletePixel', resp)
    //     this.pixelType = null
    //     this.pixelsAllowDelete = false
    //   } catch (e) {
    //     console.error('deletePixel', e)
    //     this.errorMessage = e
    //     this.isVisibleErrorDialog = true
    //   }
    //
    //   this.loadingDeletePixel = false
    //   this.confirmPixel = false
    //
    // },

    isAllowUpdateName() {
      if (this.user && this.name && this.country) {
        this.newCampaignName = this.user.trim() + ' - ' + this.name.trim() + '_' + this.country + (this.postfix ? ' ' + this.postfix.trim() : '')
        return this.campaignName !== this.newCampaignName
      }
      return false
    },

    isAllowCreateNewCampaign() {
      if (['DomainActive'].includes(this.$route.params.network) && ['miroteam'].includes(this.$route.params.account)) {
        const {keywords, keywordAmount} = this.getKeywords()

        if (keywordAmount === 0) {
          return false
        }

        if (keywords.length > 0 && keywords.some(k  => k.length > 0)) {
          if (this.pixelsIsReady) {
            if (!(this.pixelType === 'facebook' && this.pixels?.pixel_id?.length> 0)){
              return false
            }
          }else {
            return false
          }
        } else {
          return false
        }
      }


      if (this.user && this.name && this.country && this.offer) {
        this.newCampaignName = this.user.trim() + ' - ' + this.name.trim() + '_' + this.country + (this.postfix ? ' ' + this.postfix.trim() : '')
        if (this.newCampaignName < 3 && this.campaignName !== this.newCampaignName) {
          return false
        }
      } else {
        return false
      }

      if (!this.domain && this.domain.length < 1) {
        return false
      } else {
        if (!this.domainIsValid) {
          return false
        }
      }

      if (!(this.pixelType === null || this.pixelType === undefined)) {
        if (!(this.pixelsIsReady && this.pixels.pixel_id && this.pixels.pixel_id.length > 0)) {
          return false
        }
      }

      return true
    },


    copyToClipboard(text) {
      this.$copyText(text, this.$refs.dialog)
      this.snackbarText = text
      this.snackbar = true
    },

    onAddNewKeywordRow() {
      this.keyWords.push({value: '', delete: false, new: true})
      this.keywordsAmount = this.keyWords.filter(f => !f.delete).length
    },
    onDeleteKeywordRow(i) {
      this.keyWords[i].new ? this.keyWords.splice(i, 1) : this.keyWords[i].delete = true
      this.keywordsAmount = this.keyWords.filter(f => !f.delete).length
    },

    onUndoKeywordRow(i) {
      this.keyWords[i].delete = false
      this.keywordsAmount = this.keyWords.filter(f => !f.delete).length
    },

    onChangeKeywordsAmount() {
      if (this.keywordsAmount > this.keyWords.length) {
        Array.from({length: this.keywordsAmount - this.keyWords.length}, () => this.keyWords.push({
          value: '',
          delete: false,
          new: true
        }))
      } else {
        Array.from({length: this.keyWords.length - this.keywordsAmount}, (_, i) => this.keyWords.toReversed()[i].delete = true)
      }
    },

    validateDomain(domain) {
      if (this.domainIsSelected) {
        this.domainIsValid = true
        // this.domainIsSelected = false
        return true
      } else {
        this.domainIsValid = false
      }
      this.domainIsNotValid = false
      if (typeof domain === 'string' && domain.length > 0) {
        this.domainIsReadyForCheck = true
        return true
      }
      this.domainIsReadyForCheck = false
      return 'Enter domain'
    },
    validateTLDS(tlds) {
      if (Array.isArray(tlds) && tlds.length > 0) {
        return true
      }
      return 'Select TLDS domain'
    },

    validatePixel(pixelId) {
      // console.log(pixelId)
      // if (pixelId === null || (typeof pixelId === "string" || pixelId.length === 0) ) {
      //   return 'Pleas set correct Pixel ID'
      // }
      if (pixelId && pixelId.match(/^\d+$/)) {
        return true
      }
      return 'Pleas set correct Pixel ID'
    },

    onInputDomain() {
      if (this.domainIsSelected) {
        this.domainIsSelected = null
      }
    },
    async onCheckDomain() {
      if (this.tlds.length !== 1) {
        console.error('onCheckDomain this.tlds.length > 1', this.tlds.length)
        return
      }

      try {
        const resp = await api.affNetworkGetVerifyDomainAvailability({
          network: this.$route.params.network,
          account: this.$route.params.account,
          domain: this.domain + '.' + this.tlds[0]
        })
        if (resp.data.error) {
          this.isVisibleErrorDialog = true
          this.errorMessage = resp.data.msg
          return
        }
        if (Object.prototype.hasOwnProperty.call(resp.data, 'available')) {
          if (resp.data.available === true) {
            this.domainIsValid = true
          } else {
            this.domainIsNotValid = true
          }
        } else {
          this.errorMessage = 'Unknown error resp.data.available'
          this.isVisibleErrorDialog = true
        }
      } catch (e) {
        console.error('onCheckDomain', e)
        this.errorMessage = e
        this.isVisibleErrorDialog = true
      }

      // if (Math.random() * 10 > 5) {
      //
      // } else {
      //
      // }
    },
    onSelectedDomain(domain) {
      for (const tlds of this.tlds) {
        if (domain.match(new RegExp(`.${tlds}$`))) {
          this.domainIsValid = true
          this.domainIsSelected = domain.replace(`.${this.tlds}`, '')
          this.domain = domain.replace(`.${this.tlds}`, '')
        }
      }
      console.log('onSelectedDomain', domain, this.tlds, this.domain)
      this.suggestionsMenu = false
      this.domainIsNotValid = false
    },

    async onClickSuggestions() {
      this.suggest_list = []
      this.suggestionsMenu = true
      this.domainIsSelected = null
      this.suggestionsListLoading = true

      if (this.tlds.length !== 1) {
        console.error('onCheckDomain this.tlds.length > 1', this.tlds.length)
        return
      }

      try {
        const resp = await api.affNetworkGetDomainLookup({
          network: this.$route.params.network,
          account: this.$route.params.account,
          domain: this.domain,
          tld: this.tlds[0]
        })
        if (resp.data.error) {
          this.isVisibleErrorDialog = true
          this.errorMessage = resp.data.msg
          return
        }
        console.log(resp.data)
        this.suggest_list = resp.data.suggest_list.map(d => ({domain: d, select: true}))
      } catch (e) {
        this.suggest_list = []
        console.error('onClickSuggestions', e)
        this.errorMessage = e
        this.isVisibleErrorDialog = true
      } finally {
        this.suggestionsListLoading = false
      }
    }
  },
  watch: {
    dialog(v) {
      if (!v) {
        this.$emit('input', false)
      }
    },
    offer(value) {
      if (this.createNew) {

        if (value.name) {
          this.name = value.name.replace(/[-_|=`@!^&/\\#,+()$~%.'":*?<>{}\s]/g, '').replace(/PR$/, '')
        } else {
          this.name = value.replace(/[-_|=`@!^&/\\#,+()$~%.'":*?<>{}\s]/g, '').replace(/PR$/, '')
        }
        // console.log(`offer: ${value.name} -> ${this.name}`)
      }
    },
    pixelType(value, oldValue) {
      if (Object.keys(this.pixelsFromApi).length === 0) {
        this.pixelsIsReady = false
        if (value !== undefined || value !== null) {
          this.$nextTick(() => {
            if (oldValue === null) {
              this.pixelIsNew = true
            }
            if (value === 'facebook') {
              this.pixels = this.pixelsNewFaceBook
            }

            if (value === 'tiktok') {
              this.pixels = this.pixelsNewTikTok
            }
            this.pixelsIsReady = true
          })
        }
      }

    }
  }
}
</script>

<style scoped>

</style>